export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'All',
    action: 'read',
  },
  {
    title: 'Aufträge',
    route: 'auftraege',
    icon: 'FolderIcon',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Rechnungen',
    route: 'rechnungen',
    icon: 'FileTextIcon',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Kunden',
    route: 'kunden',
    icon: 'BriefcaseIcon',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Mitarbeiter',
    route: 'mitarbeiter',
    icon: 'UsersIcon',
    resource: 'Admin',
    action: 'read',
  },
];
