<template>
  <layout-horizontal>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-horizontal-layout-brand />
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="breadcrumb">
      <app-breadcrumb />
    </div>

    <div slot="footer">
      <p class="clearfix mb-0">
        <span class="float-md-right d-block d-md-inline-block mt-25">
          COPYRIGHT © {{ new Date().getFullYear() }}
          <b class="ml-25"><span class="footer-tempo">Tempo</span>Ratio</b>
        </span>
      </p>
    </div>

    <!-- <app-customizer v-if="showCustomizer" slot="customizer" /> -->
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue';
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import AppNavbarHorizontalLayoutBrand from '../components/AppNavbarHorizontalLayoutBrand.vue';
import Navbar from '../components/Navbar.vue';
import AppBreadcrumb from '../components/AppBreadcrumb.vue';

export default {
  components: {
    LayoutHorizontal,
    // AppCustomizer,
    Navbar,
    AppNavbarHorizontalLayoutBrand,
    AppBreadcrumb,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    };
  },
};
</script>
<style scoped>
.footer-tempo {
  color: #206898;
}
</style>
